import Axios from "axios";

// const deliveryUrl = process.env.REACT_APP_LOGISTIC_DELIVERY_HUB;
const deliveryUrl = process.env.REACT_APP_LOGISTIC_DELIVERY_HUB;
const api_key = process.env.REACT_APP_LOGISTIC_HUB_KEY;
const merchant_id = process.env.REACT_APP_LOGISTIC_MERCHANT_ID;
const nonitimUrl = process.env.REACT_APP_NOMINATIM_URL;
const geocodeUrl = process.env.REACT_APP_GEOCODE_URL;
const fleetTypeID = process.env.REACT_APP_FLEET_ID_BIKES
const fezEndpoint = process.env.REACT_APP_FEZ_URL;
// https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=-34.44076&lon=-58.70521
// https://geocode.maps.co/reverse?lat=&lon=&api_key=
export const sendDelivery = async (data) => {
    const url = `${deliveryUrl}/integration/newDelivery`;
    const response = await Axios.post(url, data, {
        headers: {
            "x-lh-api-key": api_key,
            "x-lh-merchant-id": merchant_id,
        }
    });

    return Promise.resolve(response);
}

export const fetchDeliveryPrice = async (details) => {
    // const latitude = details.latitude;
    // const longitude = details.longitude;
    // const pickupLatitude = details.pickupLatitude;
    // const pickupLongitude = details.pickupLongitude;
    // const response = await Axios.get(`${deliveryUrl}/integration/rate?fleetTypeId=${fleetTypeID}&fleetType=Bikes&destinationLatitude=${latitude}&destinationLongitude=${longitude}&pickupLongitude=${pickupLongitude}&pickupLatitude=${pickupLatitude}`, {
    //     headers: {
    //         "x-lh-api-key": api_key,
    //         "x-lh-merchant-id": merchant_id,
    //     }
    // });

    // return Promise.resolve(response);
    
}

export const getAddressFromLongAndLat = async (long, lat) => {
    // const response = await Axios.get(`${nonitimUrl}/reverse?format=jsonv2&lat=${lat}&lon=${long}`);
    const response = await Axios.get(`${geocodeUrl}/reverse?lat=${lat}&lon=${long}&api_key=${process.env.REACT_APP_GEOCODE_KEY}`);

    return Promise.resolve(response);
}

export const getLocation = async (details) => {
    // const response = await Axios.get(`${nonitimUrl}/search?q=${details}&format=json&addressdetails=1&limit=1&polygon_svg=1`);
    const response = await Axios.get(`${process.env.REACT_APP_GOOGLE_ADDRESS}?address=${details}&key=${process.env.REACT_APP_GOOGLE_KEY}`);

    return Promise.resolve(response);
}

//////////////////////////////////////////////////fez delivery///////////////////////////////////////
export const createDeliveryFez = async (data) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/create_fez_delivery`;
    const response = await Axios.post(url, data);

    return Promise.resolve(response);
}

export const getFezDeliveryPrice = async (data) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/fetch_delivery_price`;
    const response = await Axios.post(url, data);

    return Promise.resolve(response);
}


/////////////////////////////////////////////////record sales/////////////////////////////////
export const recordSales = async (email, data, card) => {
    const { order_items, customer } = data;
    const branch = JSON.parse(localStorage.getItem("branchdetails"));
    console.log("sss or", order_items);
    
    // const merchant = JSON.parse(localStorage.getItem("merchant_details"));
    order_items.map(items => {
        items.user_id = customer.user_id;
        items.business_branch_id = items.branch_id
        delete items.branch_id;
        // delete items.bundle_quantity;
    })
    const sale = {
        business_branch_id: branch.branch_id,
        is_paid_with_mtransfer: card === "m_transfer" ? true : false,
        is_paid_with_card: card === "card" ? true : false,
        is_paid_with_point: false,
        is_paid_with_mobile: false,
        discount_amount: null,
        has_discount: false,
        is_paid_with_cash: false,
        is_paid_with_customer_account: false,
        created_at: new Date(Date.now()).getTime(),
        reference_code: new Date(Date.now()).getTime(),
        card_payment_ref: null,
        loyalty_id: null,
        // merchant_id: undefined,
        mtier_amount: null,
        payment_reference: null,
        shared_loyalty_txn: false,
        transactions: order_items,
        user_id: customer.user_id
    }

    // console.log("email-data", data, card, sale);
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/post_urewards_sales`;
    const payload = {
        email,
        sale
    }
    const response = await Axios.post(url, payload);

    return Promise.resolve(response);   
}